$grey-tone-4: #999999;
$grey-line: #D9D9D9;
$color-green: #1E8927;
$primary-action-dark: #006192;
$primary-action-dark-hover: #008ed6;
$color-blue-action: #007AB3;
$greyscale-350: #767676;
$grey-tone-4: #999999;
$grey-tone-5: #C2C2C2

